import React, {useContext, useState} from 'react'
import Search from '../../../../components/Search'
import {GlobalProductsContext} from '../../contexts'
import {Select} from '../../../../components/Select/'
import MultipleSelect from '../../../../components/MultiSelect/MultiSelect'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import Import from '../../../../images/Import.png'

const GlobalProductsHeader = () => {
  const [showModal, setShowModal] = useState(false)
  const [message, setMessage] = useState('')
  const [filters, setFilters] = useState({
    searchTerm: '',
    selectedProducts: [],
    selectedState: '',
    selectedRep: '',
  })

  const {
    onRepFilterChange,
    onSearch,
    Representatives,
    boProductsDropdownList,
    isLoadingBoProductsDropdownList,
    handleProductOptionChange,
    AllStateData,
    isLoadingStates,
    onStateFilterChange,
    onDownloadCsv,
    isFetchingCsvData,
    GlobalProductsData,
    refetchBoProductsDropdownList,
  } = useContext(GlobalProductsContext)

  async function downloadCsvFile() {
    const payload = {
      // filtered_skus: selectedSkus?.['skus']?.join(','),
    }
    const res: any = await onDownloadCsv(payload)
    if (res?.status === 200) {
      setShowModal(true)
      setMessage(res?.data?.message)
    }
  }

  const applyFilters = () => {
    onStateFilterChange(filters.selectedState)
    onRepFilterChange(filters.selectedRep)
    handleProductOptionChange(filters.selectedProducts)
  }

  const onResetFilters = () => {
    setFilters({
      searchTerm: '',
      selectedProducts: [],
      selectedState: '',
      selectedRep: '',
    })
    onStateFilterChange('')
    onRepFilterChange('')
    handleProductOptionChange([])
    refetchBoProductsDropdownList()
  }

  return (
    <div className='mb-10'>
      <div className='d-flex justify-content-between mb-7'>
        <Search
          onSearch={(searchTerm: string) => {
            onSearch(searchTerm)
          }}
        />

        <button
          className='btn btn-outline'
          onClick={downloadCsvFile}
          disabled={isFetchingCsvData || !GlobalProductsData?.length}
        >
          <div className='d-flex align-items-center'>
            <img src={Import} alt='Import File' className='me-2 import-icon' />
            <div className='d-flex align-items-center'>
              {isFetchingCsvData ? 'Downloading...' : 'Export CSV'}
              {isFetchingCsvData && (
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              )}
            </div>
          </div>
        </button>
      </div>

      <div className='d-flex'>
        <div className='me-5'>
          <MultipleSelect
            className='min-w-250px mw-250px'
            options={Object.keys(boProductsDropdownList).map((key: any) => ({
              label: `BOP #${key} : ${boProductsDropdownList[key]}`,
              value: key,
            }))}
            placeholder='Select Product'
            isLoading={isLoadingBoProductsDropdownList}
            handleSelected={(selected) =>
              setFilters((prev) => ({...prev, selectedProducts: selected}))
            }
            selectedValues={filters.selectedProducts}
            hasSelectAll={false}
            maxSelection={10}
            isClearDefaultValue={true}
          />
          <span className='fs-7 fw-semibold'>Max 10 Product can be selected</span>
        </div>

        <div className='me-5'>
          <Select
            value={filters.selectedState}
            onChange={(e: any) => setFilters((prev) => ({...prev, selectedState: e.target.value}))}
            options={[{label: 'All States', value: ''}, ...AllStateData]}
            id={'States'}
            isLoading={isLoadingStates}
          />
        </div>

        <div className='w-200px me-5'>
          <Select
            value={filters.selectedRep}
            onChange={(e: any) => setFilters((prev) => ({...prev, selectedRep: e.target.value}))}
            options={[{label: 'Customer Rep : All', value: ''}, ...Representatives]}
            id={'reps'}
          />
        </div>

        <button className='btn btn-primary h-35px' onClick={applyFilters}>
          Apply Filters
        </button>
        {(filters.selectedProducts.length > 0 || filters.selectedState || filters.selectedRep) && (
          <button className='btn btn-outline h-35px ms-5' onClick={onResetFilters}>
            Reset Filters
          </button>
        )}
      </div>

      <ConfirmationModal
        show={showModal}
        onClose={() => {
          setShowModal(false)
          setMessage('')
        }}
        title={'Generating Your CSV File'}
        disableAction={true}
        onAction={() => {
          setShowModal(false)
          setMessage('')
        }}
        okayBtnClass={'btn-primary'}
        body={
          <div>
            <p>We are generating the data you requested for download as a CSV file.</p>
            <p>
              This process may take a few minutes. Once complete, the CSV will be sent to your
              email: <b>{message?.split(':')[1]}</b>
            </p>
          </div>
        }
      />
    </div>
  )
}

export default GlobalProductsHeader
