import {isEmptyObject} from 'jquery'

export const constructPayload = (products: any, productsData: any) => {
  const payload = {
    data: [] as any[],
    default_price: [] as any[],
  };

  products?.variants.forEach((variant: any, parentIndex: any) => {
    // Process price list changes
    variant.price_list.forEach((priceItem: any, priceIndex: any) => {
      const priceListId = priceItem.price_list_id
      const updatedPrice = priceItem[`price_${priceIndex + 1}`]

      // Get original price from productsData
      const originalPriceItem = productsData?.variants?.[parentIndex]?.price_list?.[priceIndex]
      const originalPrice = originalPriceItem?.[`price_${priceIndex + 1}`]

      // Check if price has changed and is not undefined
      if (updatedPrice !== undefined && updatedPrice !== originalPrice) {
        payload.data.push({
          variant_id: variant.variant_id,
          price_list_id: priceListId,
          price: updatedPrice,
        })
      }
    })

    // Process default price changes
    if (variant.variant_price !== undefined) {
      const originalVariantPrice = productsData?.variants?.[parentIndex]?.variant_price

      if (variant.variant_price !== originalVariantPrice) {
        payload.default_price.push({
          variant_id: variant.variant_id,
          price: variant.variant_price,
        })
      }
    }

  });
  // Process default price changes for the parent product
  if (products.default_price !== undefined) {
    const originalDefaultPrice = productsData?.default_price

    // Check if the product's default price has been updated
    if (products.default_price !== originalDefaultPrice) {
      payload.default_price.push({
        variant_id: null, // Use null for the parent product
        price: products.default_price,
      })
    }
  }

  return payload;
};

export const nameMapping: any = {
  'Value Tier Price List': 'Value Tier',
  'Tier Pro Price List': 'Tier Pro',
  'Midwest Region Tier 2 Price List': 'Midwest Region Tier 2',
  'Midwest Region Tier Pro Price List': 'Midwest Region Tier Pro',
}

export function renamePriceLists(data: any): any {
  if (!data) return {}
  const updatedData = {...data}
  Object.keys(updatedData).forEach((key) => {
    if (nameMapping[updatedData[key].name]) {
      updatedData[key].name = nameMapping[updatedData[key].name]
    }
  })
  return updatedData
}

export function renamePriceListsForDropDown(
  data: {id: number; name: string}[]
): {id: number; name: string}[] {
  if (!Array.isArray(data)) return []

  return data.map((item) => ({
    ...item,
    name: nameMapping[item.name] || item.name,
  }))
}

export function formatPriceListName(name: string): string {
  if (name === '' || name === undefined) {
    return '-'
  }
  return nameMapping[name] || name
}

export const formatToLabelValue = (data: any) => {
  const result: any = []
  if (isEmptyObject(data)) return []

  if (Array.isArray(data)) {
    const formattedData = data.map((item) => ({
      label: item.tag,
      value: item.id,
    }))
    return result.concat(formattedData)
  }
  const formattedData = Object.entries(data).map(([tag, id]) => ({
    label: tag,
    value: id,
  }))
  return result.concat(formattedData)
}

export const formatToUsersValue = (data: any) => {
  const result: any = []
  if (isEmptyObject(data)) return []

  if (Array.isArray(data)) {
    const formattedData = data.map((item) => ({
      label: item.name,
      value: item.username,
    }))
    return result.concat(formattedData)
  }
  const formattedData = Object.entries(data).map(([name, username]) => ({
    label: name,
    value: username,
  }))
  return result.concat(formattedData)
}

export const formatToPriceListValue = (data: any) => {
  const result: any = []
  if (isEmptyObject(data)) return []

  if (Array.isArray(data)) {
    const formattedData = data.map((item) => ({
      label: item.name,
      value: item.id,
    }))
    return result.concat(formattedData)
  }
  const formattedData = Object.entries(data).map(([name, id]) => ({
    label: name,
    value: id,
  }))
  return result.concat(formattedData)
}

export const columns = [
  {key: 'parent_product_sku', label: 'SKU', isSorted: false, headerStyle: 'min-w-150px'},
  {
    key: 'parent_product_name',
    label: 'Product Name',
    isSorted: true,
    headerStyle: 'min-w-400px',
  },
  {
    key: 'price_list_name',
    label: 'Price list',
    isSorted: false,
    headerStyle: 'min-w-200px',
  },
  {key: 'old_price', label: 'Old Price', isSorted: true, headerStyle: 'min-w-125px'},
  {key: 'updated_price', label: 'New Price', isSorted: true, headerStyle: 'min-w-125px'},
  {key: 'updated_by', label: 'User', isSorted: false, headerStyle: 'min-w-175px'},
  {key: 'updated_at', label: 'Last update Date', isSorted: true, headerStyle: 'min-w-175px'},
]


// Columns definition
export const columnsForRules = [
  {key: 'distributor_name', label: 'DISTRO NAME', isSorted: true, headerStyle: 'min-w-150px'},
  {key: 'rule_name', label: 'RULE NAME', isSorted: true, headerStyle: 'min-w-150px'},
  {key: 'category_names', label: 'Category', isSorted: false, headerStyle: 'min-w-250px'},
  {key: 'product_count', label: 'PRODUCTS', isSorted: false, headerStyle: 'min-w-100px'},
  {key: 'created_at', label: 'CREATED DATE', isSorted: true, headerStyle: 'min-w-150px'},
  {key: 'updated_at', label: 'LAST UPDATED DATE', isSorted: true, headerStyle: 'min-w-200px'},
  {key: 'product_count', label: 'Status', isSorted: false, headerStyle: 'min-w-100px'},
  {
    key: 'sync',
    label: 'SYNC',
    isSorted: false,
    headerStyle: 'min-w-80px',
    style: 'justify-content-center',
  },
  {
    key: 'action',
    label: 'ACTION',
    isSorted: false,
    headerStyle: 'w-80px text-center',
    render: true,
  },
]

// Dummy data array
export const PriceListData = [
  {
    distributor_name: 'TCD',
    rule_name: 'TCD Price',
    product_count: 457,
    updated_at: '25 Oct 2023, 6:43 am',
    action: '...',
    status: '',
  },
  {
    distributor_name: 'MBD',
    rule_name: 'MBD Price',
    product_count: 690,
    updated_at: '15 Oct 2023, 5:43 am',
    action: '...',
    status: '',
  },
  {
    distributor_name: 'TCD',
    rule_name: 'Distro',
    product_count: 194,
    updated_at: '10 Oct 2023, 7:43 pm',
    action: '...',
    status: '',
  },
  {
    distributor_name: 'MBC',
    rule_name: 'Tier',
    product_count: 5000,
    updated_at: '1 Oct 2023, 5:43 am',
    action: '...',
    status: '',
  },
]



export const CreatePayload = (formData: any, Distributors: any) => {
  const payload = {
    distributor_id: Number(formData.distributor_id), // Convert to numeric
    rule_name: formData.rule_name,
    categories: formData.categories.map((category: {id: number; name: string}) => category.id),
    price_calculation: formData.price_calculation,
    price_group: Number(formData.price_group), // Convert to numeric
    markup_price: Number(formData.markup_price), // Convert to numeric
    distributor_name:
      Distributors.find((distributor: any) => distributor.value === formData.distributor_id)
        ?.label || '', // Map `distributor_name` based on `distributor_id`
  }

  return payload
}


export const dataToMultiSelectOptions = (data: any) => {
  const res: any = []

  data.forEach((i: any) => {
    res.push({
      value: i,
      label: i,
    })
  })

  return res
}


export const parseCustomerSpecificPriceData = (data: any) => {
  if (!data?.length) return []
  return data?.map((item: any) => ({
    ...item,
    id: `${item.product_id}:${item.customer_id}`, // Combine product_id and customer_id
  }))
}

export const samplePriceListCSVData = [
  {
    'Product name': 'Product A',
    SKU: 'SKU001',
    Wholesale: 100,
    'Value Tier Price List': 105,
    'Tier 1 Price List': 110,
    'Tier 2 Price List': 115,
    'Tier Pro Price List': 120,
    Distro: 95,
    VIP: 90,
    Partner: 88,
    'Partner TCD': 85,
    'Partner MVD': 80,
  },
  {
    'Product name': 'Product B',
    SKU: 'SKU002',
    Wholesale: 200,
    'Value Tier Price List': 210,
    'Tier 1 Price List': 220,
    'Tier 2 Price List': 230,
    'Tier Pro Price List': 240,
    Distro: 190,
    VIP: 180,
    Partner: 175,
    'Partner TCD': 170,
    'Partner MVD': 160,
  },
  {
    'Product name': 'Product C',
    SKU: 'SKU003',
    Wholesale: 300,
    'Value Tier Price List': 315,
    'Tier 1 Price List': 330,
    'Tier 2 Price List': 345,
    'Tier Pro Price List': 360,
    Distro: 285,
    VIP: 270,
    Partner: 265,
    'Partner TCD': 255,
    'Partner MVD': 240,
  },
]

export const priceListCsvConfig = {
  requiredHeaders: [
    'Product name',
    'SKU',
    'Wholesale',
    'Value Tier Price List',
    'Tier 1 Price List',
    'Tier 2 Price List',
    'Tier Pro Price List',
    'Distro',
    'VIP',
    'Partner',
    'Partner TCD',
    'Partner MVD',
  ],
  duplicates: {sku: false},
  isNullable: false,
  nullableColumns: [
    'Product name',
    'Wholesale',
    'Value Tier Price List',
    'Tier 1 Price List',
    'Tier 2 Price List',
    'Tier Pro Price List',
    'Distro',
    'VIP',
    'Partner',
    'Partner TCD',
    'Partner MVD',
  ],
  isReplaceable: true,
  replacePartially: true,
  isUseRequestedHeaderAsaKey: true,
  allowDuplicateRow: true,
  maxRows: 100,
  types: {
    sku: 'string',
    wholesale: 'positive-integer',
    'value tier price list': 'positive-integer',
    'tier 1 price list': 'positive-integer',
    'tier 2 price list': 'positive-integer',
    'tier pro price list': 'positive-integer',
    distro: 'positive-integer',
    vip: 'positive-integer',
    partner: 'positive-integer',
    'partner tcd': 'positive-integer',
    'partner mvd': 'positive-integer',
  },

  customErrors: {
    headerMismatch: () =>
      'The file you are trying to upload is not a valid CSV file. Please download a sample CSV for reference.',

    invalidFileType: () =>
      'The file you are trying to upload is not a valid CSV file. Please download a sample CSV for reference.',

    emptyCell: (rowNumber: number, headerName: string) =>
      `Row ${rowNumber}: ${headerName} is blank. Please enter a valid ${headerName}.`,

    duplicateData: (skuName: string, rowNumbers: number[]) =>
      `Row ${rowNumbers.join(', ')}: Duplicate SKU found. Each SKU must be unique.`,

    replacePartially:
      'The following SKU(s) already exist in the system. Do you want to proceed with overwriting these data?',

    maxRowsLimit: (currentRows: number, maxLimit: number) =>
      `The current CSV contains ${currentRows} Rows. 
        The maximum allowed Rows are ${maxLimit} for a quick operation. Please reduce the number of Rows to proceed with the import.`,

    invalidDataType: (headerName: string, rowIndex: number) =>
      `Row ${rowIndex} ${headerName}: Price is not valid.`,
  },
}


export const dataToPriceListCSV = (data: any) => {
  const results: any = []

  data.forEach((item: any) => {
    results.push({
      SKU: item?.child_sku,
      Qty: item?.qty || 0,
    })
  })

  return results
}

export function transformPriceListData(data: any) {
  const PRICE_LIST_MAPPING: any = {
    1: 'Wholesale',
    4: 'Value Tier',
    7: 'Tier 1 Price List',
    8: 'Tier 2 Price List',
    53: 'Tier Pro',
    52: 'Distro',
    51: 'VIP',
    100054: 'Partner TCD',
    100055: 'Partner MVD',
  }
  return data.map(({variants}: any) => {
    const variant = variants?.[0] || {}
    const row: any = {
      SKU: variant.variant_sku || '',
      Wholesale: '',
      'Value Tier': '',
      'Tier 1 Price List': '',
      'Tier 2 Price List': '',
      'Tier Pro': '',
      Distro: '',
      VIP: '',
      'Partner TCD': '',
      'Partner MVD': '',
    }

    variant.price_list?.forEach((p: any) => {
      const label = PRICE_LIST_MAPPING[p.price_list_id]
      if (label) {
        const priceValue =
          p.price ||
          p.amount ||
          Object.values(p).find(
            (v) => (typeof v === 'number' || (typeof v === 'string' && v)) && v !== ''
          )
        if (priceValue !== undefined) row[label] = priceValue
      }
    })

    return row
  })
}
