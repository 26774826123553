/* eslint-disable react-hooks/exhaustive-deps */
import {useContext, useEffect} from 'react'
import {ProfitabilityContext} from '../../context'
import {DynamicTable} from '../../../../components/DynamicTable'
import {parseProfitabilityRow, PROFITABILITY_COLUMNS, PROFITABILITY_COLUMNS_WithShorting} from '../../utils'
import ProfitabilityRow from './CustomersTableRow'
import {Link} from 'react-router-dom'

const CustomersTable = ({isFullView}: any) => {
  const {profitabilityData, isLoading, onSortingChange, onDateChangeCustomers} =
    useContext(ProfitabilityContext)

  useEffect(() => {
    if (isFullView) {
      onDateChangeCustomers('', '')
    }
  }, [isFullView])

  return (
    <div className={isFullView ? 'mt=15' : 'card border'}>
      <div className={isFullView ? '' : 'card-body p-7'}>
        {!isFullView && (
          <div className='d-flex flex-row justify-content-between mb-8'>
            <h2 className='text-dark fw-bolder mb-0'>Customer</h2>
            <div className='mt-2'>
              <Link
                to='/analytics/profitability/customers'
                className='text-primary text-hover-dark text-decoration-underline fw-semibold'
              >
                View All
              </Link>
            </div>
          </div>
        )}
        <DynamicTable
          data={isFullView ? profitabilityData : profitabilityData?.slice(0, 5)}
          sortableColumns={isFullView ? PROFITABILITY_COLUMNS_WithShorting : PROFITABILITY_COLUMNS}
          loading={isLoading}
          noDataMessage='No profitability data found'
          onSortingChange={onSortingChange}
          tableClass='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2 mb-0'
          TableRow={ProfitabilityRow}
          rowParsingFunction={parseProfitabilityRow}
        />
      </div>
    </div>
  )
}

export default CustomersTable
